<template>
  <div class="login"></div>
</template>
<script>
import { getToken } from '@/utils/auth'
export default {
  name: "Login",
  data() {
    return {}
  },
  created() {
    if (getToken()) { 
      sessionStorage.setItem('projectCode',this.$route.query.projectCode)
      this.$router.push({ path:"/" }); 
    } else {
       window.location.href=process.env.VUE_APP_RE_LOGIN
    }
  }
};
</script>

