import Cookies from "js-cookie";
import Config from "@/settings";

const TokenKey = Config.TokenKey;

export function getToken() {
  // removeOldToken()
  return Cookies.get(TokenKey);
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, {
      expires: Config.tokenCookieExpires,
      domain: document.domain,
    }); // 登录cookie添加到主域名下
  } else return Cookies.set(TokenKey, token, { domain: document.domain });
}

export function removeToken() {
  return Cookies.remove(TokenKey, { domain: document.domain });
}

export function removeOldToken() {
  if (
    document.domain == "localhost" ||
    /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(document.domain)
  )
    return;
  return Cookies.remove(TokenKey);
}
