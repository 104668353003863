var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "decrypt_box" },
    [
      _c(
        "span",
        {
          ref: "copy",
          class: { pointer: !_vm.needDecrypt && _vm.phone },
          on: {
            click: function ($event) {
              _vm.needDecrypt ? "" : _vm.copyFn(_vm.phone, $event)
            },
          },
        },
        [_vm._v(_vm._s(_vm.phone))]
      ),
      _vm._v(" "),
      _vm.phone
        ? _c("el-button", {
            attrs: {
              type: "text",
              icon: "el-icon-copy-document",
              title: "点我复制解密手机号",
            },
            on: {
              click: function ($event) {
                _vm.needDecrypt
                  ? _vm.decryptBtn($event)
                  : _vm.copyFn(_vm.phone, $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }