var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-row", { staticClass: "panel-group", attrs: { gutter: 40 } }, [
    _c("p", { staticStyle: { "font-size": "50px", "text-align": "center" } }, [
      _vm._v("欢迎进入红杏林神农系统"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }