import request from "@/utils/request";
export function login(username, password, code, uuid) {
  return request({
    url: "/admin-auth/auth/login",
    method: "post",
    data: {
      username,
      password,
      code,
      uuid,
    },
  });
}

export function getInfo() {
  return request({
    url: "/admin-auth/auth/info",
    method: "get",
  });
}

export function getCodeImg() {
  return request({
    url: "/admin-auth/auth/code",
    method: "get",
  });
}

export function logout() {
  return request({
    url: process.env.VUE_APP_GODNONG_BASE_API
      ? process.env.VUE_APP_GODNONG_BASE_API + "admin-auth/auth/logout"
      : "admin-auth/auth/logout",
    method: "delete",
  });
}
