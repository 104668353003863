/**代替原来的手机号展示，提供手机号解密获取用户真实手机号
需要展示用的手机号（脱敏数据）phone 需要手机号密文（用于解密）encryptPhone
解密后把真实手机号回传给phone用户展示 提供复制功能 解密后即复制 */
<template>
  <div class="decrypt_box">
    <span
      :class="{ pointer: !needDecrypt && phone }"
      ref="copy"
      @click="needDecrypt ? '' : copyFn(phone, $event)"
      >{{ phone }}</span
    >
    <el-button
      v-if="phone"
      type="text"
      icon="el-icon-copy-document"
      @click="needDecrypt ? decryptBtn($event) : copyFn(phone, $event)"
      title="点我复制解密手机号"
    ></el-button>
  </div>
</template>

<script>
import { getUnprotectPhone } from "@/api/common-server.js";
import clipboard from "@/utils/clipboard";
export default {
  name: "DecryptPhone",
  props: {
    phone: {
      // 展示用的***手机号
      type: Number | String,
    },
    encryptPhone: {
      // 手机号密文
      type: Number | String,
    },
  },
  computed: {
    // 是否需要解密 当前有密文才解密
    needDecrypt() {
      return this.phone && /\*/.test(this.phone) && this.encryptPhone;
    },
  },
  methods: {
    // 调用解密
    async decryptBtn() {
      console.log(this.encryptPhone);
      const { code, data, message } = await getUnprotectPhone({
        encryptionInfo: this.encryptPhone,
      });
      if (code == 200) {
        // 更新真实手机号到显示页面
        this.$emit("update:phone", data);
        this.$nextTick(() => {
          // 数据渲染到页面后 模拟点击复制
          this.$refs.copy.click();
        });
        return;
      }
      this.$message.error(message);
    },
    // 解密完后复制 clipboard复制不支持异步 采用模拟点击替代
    copyFn(data, e) {
      clipboard(data, e, "复制成功", "复制失败");
    },
  },
};
</script>

<style>
.el-tooltip:has(.decrypt_box) {
  padding: 0 !important;
}
</style>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
