import { getPublicImgUrl, queryDicByType } from "@/api/upload/upload";
import { getShopList } from "@/api/shop/index";

const state = {
  publicImgUrl: "",
  dics: "",
  shopList: undefined,
};
const mutations = {
  SETTING_PUBLIC_IMGURL(state, data) {
    state.publicImgUrl = data;
  },
  SETTING_DICS(state, data) {
    state.dics = data;
  },
  SET_SHOP_LIST(state, data) {
    state.shopList = data;
  },
};
const actions = {
  // 获取图片公共 域名
  SetPublicImgUrl({ commit }) {
    return new Promise((resolve, reject) => {
      getPublicImgUrl().then((res) => {
        if (res.code == 200) {
          commit("SETTING_PUBLIC_IMGURL", res.data.filePath);
        } else {
          console.log(res.message);
        }
      });
    });
  },
  // 获取公共字典表
  queryDicByType({ commit }) {
    return new Promise((resolve, reject) => {
      let types = [
        "licenType",
        "signType",
        "busiType",
        "busiRole",
        "bankType",
        "bank",
        "yopStatus",
        "orderStatus",
        "payType",
        "channel",
        "after_sales_status",
      ];
      queryDicByType({ type: types.join() }).then((res) => {
        if (res.code == 200) {
          commit("SETTING_DICS", res.data);
        } else {
          console.log(res.message);
        }
      });
    });
  },
  // 获取商铺列表
  GetShopList({ commit }) {
    return new Promise((resolve, reject) => {
      if (!state.shopList) {
        const Odata = {
          page: 1,
          pageSize: 100,
        };
        getShopList(Odata).then((res) => {
          if (res.code === 200) {
            const list = res.data.data;
            commit("SET_SHOP_LIST", list);
            resolve(list);
            return;
          }
          reject(res);
        });
        return;
      }
      resolve(state.shopList);
    });
  },
};

export default {
  namespaced: false,
  state,
  mutations,
  actions,
};
