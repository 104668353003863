import request from "@/utils/request";
import { encrypt } from "@/utils/rsaEncrypt";

export function add(data) {
  return request({
    url: "admin-auth/api/users",
    method: "post",
    data,
  });
}

export function del(ids) {
  return request({
    url: "admin-auth/api/users",
    method: "delete",
    data: ids,
  });
}

export function edit(data) {
  return request({
    url: "admin-auth/api/users",
    method: "put",
    data,
  });
}

export function editUser(data) {
  return request({
    url: "admin-auth/api/users/center",
    method: "put",
    data,
  });
}

export function updatePass(user) {
  const data = {
    oldPass: encrypt(user.oldPass),
    newPass: encrypt(user.newPass),
  };
  return request({
    url: "admin-auth/api/users/updatePass/",
    method: "post",
    data,
  });
}

export function updateEmail(form) {
  const data = {
    password: encrypt(form.pass),
    email: form.email,
  };
  return request({
    url: "admin-auth/api/users/updateEmail/" + form.code,
    method: "post",
    data,
  });
}

export function projeckOrg(data) {
  return request({
    url: "admin-auth/api/project/org",
    method: "get",
    params: data,
  });
}
export function projectAll(data) {
  return request({
    url: "admin-auth/api/project/all",
    method: "get",
    params: data,
  });
}

/**
 * @title 根据主体查询所有岗位
 * @param {*} data
 * @returns
 */
export function findTitleAll(data) {
  return request({
    url: "admin-auth/api/users/findTitleAll",
    method: "get",
    params: data,
  });
}
export default { add, edit, del };
