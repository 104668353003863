// import "./public-path";
import Vue from "vue";

import Cookies from "js-cookie";

import "normalize.css/normalize.css";

import Element from "element-ui";
//
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";

const VUE_APP_VERSION = require("../package.json").version;
//对比版本号是否一致 更新缓存并强制刷新
if (VUE_APP_VERSION != localStorage.getItem("AppVersion")) {
  window.localStorage.setItem("AppVersion", VUE_APP_VERSION);
  window.location.reload(true);
}

// 数据字典
import dict from "./components/Dict";

// 权限指令
import permission from "./components/Permission";
import "./assets/styles/element-variables.scss";
// global css
import "./assets/styles/index.scss";

// 代码高亮
import VueHighlightJS from "vue-highlightjs";
import "highlight.js/styles/atom-one-dark.css";

import App from "./App";
import store from "./store";
import router from "./router/routers";

import "./assets/icons"; // icon
import "./router/index"; // permission control

import ElSTable from "./components/ElSTable";
import FilterColumn from "./components/ElSTable/Filter";
import { loadScript } from "@/utils/index.js";
loadScript(
  "https://imgcache.qq.com/open/qcloud/video/tcplayer/libs/hls.min.0.12.4.js"
);
loadScript(
  "https://imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.v4.min.js"
);
loadScript("https://cdn-go.cn/cdn/vod-js-sdk-v6/latest/vod-js-sdk-v6.js");
import DecryptPhone from "./components/DecryptPhone"; // 展示手机号并提供解密获取真实手机号

Vue.prototype.openPage = function (name, par) {
  this.$router.push({ name: name, query: par });
};
Vue.prototype.goBack = function () {
  this.$router.back();
};

Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(DecryptPhone);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get("size") || "small", // set element-ui default size
});
Vue.use(ElSTable);
Vue.use(FilterColumn);

Vue.prototype.$alertMsgBox = function alert() {
  return this.$prompt("您确定要删除此数据吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputPlaceholder: "请输入DELETE，进行删除",
    inputPattern: /^[D][E][L][E][T][E]+$/,
    inputErrorMessage: "请输入DELETE，进行删除",
  }).then(() => {});
};

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});

// el-select选择，动态加载更多。
var timer = "";
Vue.directive("loadmore", {
  inserted(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    if (SELECTWRAP_DOM) {
      SELECTWRAP_DOM.addEventListener("scroll", function () {
        // + 10 : 防止浏览器有时候不触底
        const CONDITION =
          this.scrollHeight - this.scrollTop <= this.clientHeight + 10;
        if (CONDITION) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            binding.value();
          }, 100);
        }
      });
    }
  },
});
