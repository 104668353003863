import request from "@/utils/request";

export function getMenusTree() {
  return request({
    url: "admin-auth/api/menus/tree",
    method: "get",
  });
}

export function buildMenus() {
  return request({
    url: "admin-auth/api/menus/build",
    method: "get",
  });
}

export function add(data) {
  api / menus;
  return request({
    url: "admin-auth/api/menus",
    method: "post",
    data,
  });
}

export function del(ids) {
  return request({
    url: "admin-auth/api/menus",
    method: "delete",
    data: ids,
  });
}

export function edit(data) {
  return request({
    url: "admin-auth/api/menus",
    method: "put",
    data,
  });
}

export function addOperateRecord(data) {
  return request({
    url: "admin-auth/api/menus/addOperateRecord",
    method: "post",
    data,
  });
}

export default { add, edit, del, getMenusTree, addOperateRecord };
