var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { xs: 24, sm: 24, md: 8, lg: 6, xl: 5 },
            },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("个人信息")])]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "show-file-list": false,
                            "on-success": _vm.handleSuccess,
                            "on-error": _vm.handleError,
                            headers: _vm.headers,
                            action: _vm.updateAvatarApi,
                            data: { busiName: 19 },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: _vm.user.avatar
                                ? _vm.$store.state.public.publicImgUrl +
                                  _vm.user.avatar
                                : _vm.Avatar,
                              title: "点击上传头像",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "user-info" }, [
                    _c("li", [
                      _c(
                        "div",
                        { staticStyle: { height: "100%" } },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "login" } }),
                          _vm._v(" 登录账号\n                "),
                          _c("div", { staticClass: "user-right" }, [
                            _vm._v(_vm._s(_vm.user.username)),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("svg-icon", { attrs: { "icon-class": "user1" } }),
                        _vm._v(" 用户昵称\n              "),
                        _c("div", { staticClass: "user-right" }, [
                          _vm._v(_vm._s(_vm.user.nickName)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("svg-icon", { attrs: { "icon-class": "phone" } }),
                        _vm._v(" 手机号码\n              "),
                        _c("div", { staticClass: "user-right" }, [
                          _vm._v(_vm._s(_vm.user.phone)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("svg-icon", { attrs: { "icon-class": "email" } }),
                        _vm._v(" 用户邮箱\n              "),
                        _c("div", { staticClass: "user-right" }, [
                          _vm._v(_vm._s(_vm.user.email)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("svg-icon", { attrs: { "icon-class": "dept" } }),
                        _vm._v(" 所属部门\n              "),
                        _c("div", { staticClass: "user-right" }, [
                          _vm._v(
                            _vm._s(_vm.user.dept) + " / " + _vm._s(_vm.user.job)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("svg-icon", { attrs: { "icon-class": "anq" } }),
                        _vm._v(" 安全设置\n              "),
                        _c("div", { staticClass: "user-right" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.$refs.pass.dialog = true
                                },
                              },
                            },
                            [_vm._v("修改密码")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.$refs.email.dialog = true
                                },
                              },
                            },
                            [_vm._v("修改邮箱")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 16, lg: 18, xl: 19 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "用户资料", name: "first" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "form",
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                model: _vm.form,
                                rules: _vm.rules,
                                size: "small",
                                "label-width": "65px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "昵称", prop: "nickName" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "35%" },
                                    model: {
                                      value: _vm.form.nickName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "nickName", $$v)
                                      },
                                      expression: "form.nickName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#c0c0c0",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [_vm._v("用户昵称不作为登录使用")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "手机号", prop: "phone" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "35%" },
                                    model: {
                                      value: _vm.form.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "phone", $$v)
                                      },
                                      expression: "form.phone",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#c0c0c0",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [_vm._v("手机号码不能重复")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "性别" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticStyle: { width: "178px" },
                                      model: {
                                        value: _vm.form.sex,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "sex", $$v)
                                        },
                                        expression: "form.sex",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "男" } },
                                        [_vm._v("男")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "女" } },
                                        [_vm._v("女")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        loading: _vm.saveLoading,
                                        size: "mini",
                                        type: "primary",
                                      },
                                      on: { click: _vm.doSubmit },
                                    },
                                    [_vm._v("保存配置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "操作日志", name: "second" } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.data },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "description", label: "行为" },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "requestIp", label: "IP" },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": true,
                                  prop: "address",
                                  label: "IP来源",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "browser", label: "浏览器" },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "time",
                                  label: "请求耗时",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.time <= 300
                                          ? _c("el-tag", [
                                              _vm._v(
                                                _vm._s(scope.row.time) + "ms"
                                              ),
                                            ])
                                          : scope.row.time <= 1000
                                          ? _c(
                                              "el-tag",
                                              { attrs: { type: "warning" } },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.time) + "ms"
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-tag",
                                              { attrs: { type: "danger" } },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.time) + "ms"
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c(
                                "el-table-column",
                                {
                                  attrs: { align: "right" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.parseTime(
                                                  scope.row.createTime
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("template", { slot: "header" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          float: "right",
                                          cursor: "pointer",
                                        },
                                        on: { click: _vm.init },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    创建日期"
                                        ),
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                          staticStyle: {
                                            "margin-left": "40px",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pagebox" },
                            [
                              _c("el-pagination", {
                                staticStyle: { "margin-top": "8px" },
                                attrs: {
                                  total: _vm.total,
                                  "current-page": _vm.page + 1,
                                  layout: "total, prev, pager, next, sizes",
                                },
                                on: {
                                  "size-change": _vm.sizeChange,
                                  "current-change": _vm.pageChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("updateEmail", { ref: "email", attrs: { email: _vm.user.email } }),
      _vm._v(" "),
      _c("updatePass", { ref: "pass" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }