<template>
  <div class="dashboard-container">
    <div class="dashboard-editor-container">
      <panel-group />
    </div>
  </div>
</template>

<script>
import PanelGroup from "./dashboard/PanelGroup";
import { count } from "@/api/monitor/visits";
/**
 * 记录访问，只有页面刷新或者第一次加载才会记录
 */
count().then((res) => {});

export default {
  name: "Dashboard",
  components: {
    PanelGroup,
  },
  data: function () {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-editor-container {
  padding: 18px 22px 22px 22px;
  // background-color: rgb(240, 242, 245);
  margin-bottom: 20px;
  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
.block {
  text-align: right;
  margin-top: 25px;
}
.btn {
  margin-bottom: 10px;
}
</style>
