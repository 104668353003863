/** 用户自主选择需要显示的的表格字段 传入refresh 刷新数据
自动缓存用户选择isCache 默认true false不缓存 当前页多个表格，传入tableKey区分 */
<template>
  <div class="filter_table">
    <el-popover placement="bottom-start" trigger="click">
      <el-button slot="reference" type="primary" plain icon="el-icon-s-grid">
        <i class="fa fa-caret-down" aria-hidden="true" />
        自定义表格
      </el-button>
      <div class="check_box">
        <el-checkbox
          v-model="allColumnsSelected"
          :indeterminate="allColumnsSelectedIndeterminate"
          @change="handleCheckAllChange"
          :key="tableKey + columns.length"
        >
          全选
        </el-checkbox>
        <!-- <el-checkbox-group v-model="filterColumns" @change="handleCheckedTableColumnsChange"> -->
        <el-checkbox
          v-for="item in backColumns[tableKey].filter(
            (item) => item.type != 'selection'
          )"
          :key="item.label + tableKey + columns.length"
          v-model="item.visible"
          @change="handleCheckedTableColumnsChange(item)"
          >{{ item.label }}</el-checkbox
        >
      </div>
      <!-- </el-checkbox-group> -->
    </el-popover>
    <el-button
      type="primary"
      icon="el-icon-refresh"
      @click="refreshBtn"
    ></el-button>
  </div>
</template>

<script>
import { cache } from "@/utils";
export default {
  name: "FilterColumn",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    tableKey: {
      // 单页 多个不同columns表格，传入每一个的tab值
      type: Number | String,
      default: "default",
    },
    isCache: {
      // 是否缓存 用户历史自主设置
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allColumnsSelected: true,
      allColumnsSelectedIndeterminate: false,
      backColumns: {},
    };
  },
  created() {
    // console.log('table created', this.columns, this.$route, this.$store.state)
    this.backColumns[this.tableKey] = [];
    this.getStorage(); // 获取缓存表头数据
  },
  mounted() {
    console.log("mounted", this);
    // this.getStorage() // 获取缓存表头数据
  },
  watch: {
    tableKey(val) {
      // 同一页面多个表格columns不同 共用时 切换
      // console.log('监听更新' ,val)
      this.backColumns[this.tableKey] = this.backColumns[this.tableKey] || [];
      this.getStorage(); // 获取缓存表头数据
    },
  },
  methods: {
    handleCheckAllChange(val) {
      // if (!val) {
      //   this.allColumnsSelected = true
      //   return
      // }
      if (!val) return (this.allColumnsSelected = true);
      this.backColumns[this.tableKey].forEach((item) => {
        item.visible = !!val;
      });
      // const filterColumns = val ? [...this.backColumns[this.tableKey]] : this.backColumns[this.tableKey].filter(item => item.type == 'selection');
      this.allColumnsSelected = val;
      this.allColumnsSelectedIndeterminate = false;
      this.setStorage(
        this.backColumns[this.tableKey].map((item) => {
          return { prop: item.prop, visible: item.visible };
        })
      );
      this.$emit("update:columns", this.backColumns[this.tableKey]); // 结合 修饰符 .sync 更新父组件columns
    },
    handleCheckedTableColumnsChange(item) {
      // console.log("handleCheckedTableColumnsChange", val, this.backColumns);
      const filterColumns = this.backColumns[this.tableKey].filter(
        (item) => item.visible
      );
      const checkedCount = filterColumns.length;
      this.allColumnsSelected =
        checkedCount == this.backColumns[this.tableKey].length;
      this.allColumnsSelectedIndeterminate =
        checkedCount > 0 &&
        checkedCount < this.backColumns[this.tableKey].length;
      if (filterColumns.filter((item) => item.type != "selection").length < 2) {
        this.$message.info("请至少选择两项");
        this.$nextTick(() => {
          item.visible = true;
        });
        return;
      }
      this.setStorage(
        this.backColumns[this.tableKey].map((item) => {
          return { prop: item.prop, visible: item.visible };
        })
      ); // 设置表头数据缓存
      this.$emit("update:columns", this.backColumns[this.tableKey]); // 结合 修饰符 .sync 更新父组件columns
    },
    refreshBtn() {
      this.$emit("refresh");
    },
    getStorage() {
      const route = this.$route.name;
      const userId = this.$store.state.user.user.id;
      const columns =
        ((cache.get(userId) || {})[route] || {})[this.tableKey] || [];
      const list =
        this.backColumns[this.tableKey].length <= 0
          ? this.columns
          : this.backColumns[this.tableKey];
      this.backColumns[this.tableKey] = list.map((item) => {
        if (!item.hasOwnProperty("visible")) {
          item.visible = true;
        }
        if (this.isCache) {
          const column = columns.find((column) => column.prop == item.prop); // 获取缓存对应的设置
          if (column) {
            item.visible = column.visible;
          }
        }
        return { ...item };
      });
      this.allColumnsSelected = true;
      this.allColumnsSelectedIndeterminate = false;
      if (columns.length > 0 && this.isCache) {
        // 有缓存 采用缓存
        const filterColumns = this.backColumns[this.tableKey].filter(
          (item) => item.visible
        );
        if (filterColumns.length < this.backColumns[this.tableKey].length) {
          this.allColumnsSelected = false;
          this.allColumnsSelectedIndeterminate = true;
        }
        this.$nextTick(() => {
          this.$emit("update:columns", this.backColumns[this.tableKey]); // 结合 修饰符 .sync 更新父组件columns
        });
      }
    },
    setStorage(columns) {
      if (!this.isCache) return;
      const route = this.$route.name;
      const userId = this.$store.state.user.user.id;
      const userSet = cache.get(userId) || {};
      userSet[route] = userSet[route] || {};
      userSet[route][this.tableKey] = columns;
      cache.set(userId, userSet);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter_table {
  display: inline-block;
}
.check_box {
  max-height: 500px;
  overflow: auto;
  &::v-deep .el-checkbox {
    display: block;
  }
}
</style>
