exports = module.exports = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#8f949e",
	"menuActiveText": "#f4f4f5",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#192539",
	"menuHover": "#2e61d7",
	"subMenuBg": "#192539",
	"subMenuHover": "#192539",
	"sideBarWidth": "205px"
};