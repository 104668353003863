var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog,
            "close-on-click-modal": false,
            "before-close": _vm.cancel,
            title: _vm.title,
            "append-to-body": "",
            width: "475px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
            close: _vm.cancel,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
                "label-width": "88px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { "auto-complete": "on" },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.codeLoading,
                        disabled: _vm.isDisabled,
                        size: "small",
                      },
                      on: { click: _vm.sendCode },
                    },
                    [_vm._v(_vm._s(_vm.buttonName))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "验证码", prop: "code" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "当前密码", prop: "pass" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { type: "password" },
                    model: {
                      value: _vm.form.pass,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pass", $$v)
                      },
                      expression: "form.pass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }