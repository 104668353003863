import request from "@/utils/request";
import qs from "qs";

export function initData(url, params) {
  return request({
    url: "admin-auth/" + url + "?" + qs.stringify(params, { indices: false }),
    method: "get",
  });
}

export function download(url, params) {
  return request({
    url: url + "?" + qs.stringify(params, { indices: false }),
    method: "get",
    responseType: "blob",
  });
}

export function newDownload(params, sign) {
  return request({
    url: "common-server/pc/down/spring",
    method: "post",
    data: { requestParams: JSON.stringify(params), sign: sign },
  });
}

//下载中心-统一下载 spring方式
export function downloadCenter(data, sign, data1) {
  return request({
    url: "/common-server/pc/down/spring",
    method: "post",
    data: {
      requestParams: JSON.stringify(data),
      sign: sign,
      mappings: JSON.stringify(data1),
    },
  });
}
