import request from "@/utils/request";

export function addShop(data) {
  return request({
    url: "/curriculum/pc/shop/addShop",
    method: "post",
    data: data,
  });
}

export function addShopLevel(data) {
  return request({
    url: "/curriculum/pc/shop/addShopLevel",
    method: "post",
    data: data,
  });
}

export function editShop(data) {
  return request({
    url: "/curriculum/pc/shop/editShop",
    method: "post",
    data: data,
  });
}

export function editShopLevel(data) {
  return request({
    url: "/curriculum/pc/shop/editShopLevel",
    method: "post",
    data: data,
  });
}

export function getShopLevelList(data) {
  return request({
    url: "/curriculum/pc/shop/getShopLevelList",
    method: "get",
    params: data,
  });
}

export function getShopList(data) {
  return request({
    url: "/curriculum/pc/shop/list",
    method: "get",
    params: data,
  });
}
