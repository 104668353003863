<template>
  <el-row :gutter="40" class="panel-group">
    <p style="font-size: 50px; text-align: center">欢迎进入红杏林神农系统</p>
    <!-- <el-col
      :xs="12"
      :sm="12"
      :lg="4"
      class="card-panel-col"
      v-for="(items,index) in menu" 
      :key="index"
    >

       <div @click="linkDetails(items.projectUrl,items.isJump, items.projectCode)"> 
          <img :src="items.projectExplain" class="iconPointer">
          <h3 class="title">{{ items.projectName }}</h3>
      </div>
    </el-col> -->
  </el-row>
</template>

<script>
import CountTo from "vue-count-to";
import { get } from "@/api/monitor/visits";
import { projectAll } from "@/api/system/user.js";
export default {
  components: {
    CountTo,
  },
  data() {
    return {
      count: { newIp: 0, newVisits: 0, recentIp: 0, recentVisits: 0 },
      menu: [],
    };
  },
  mounted() {
    get().then((res) => {
      this.count.newIp = res.newIp;
      this.count.newVisits = res.newVisits;
      this.count.recentIp = res.recentIp;
      this.count.recentVisits = res.recentVisits;
    });
    projectAll().then((res) => {
      console.log(res);
      this.menu = res;
    });
  },
  methods: {
    linkDetails(e, isJump, code) {
      if (!isJump) {
        let url = e + "/login?projectCode=" + code;
        window.open(url);
      }
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.panel-group {
  margin-top: 18px;
  .card-panel-col {
    margin-bottom: 32px;
    text-align: center;
    .iconPointer {
      width: 100px;
      cursor: pointer;
    }
    .title {
      color: #707070;
      margin-top: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .card-panel {
    height: 108px;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    .icon-people {
      color: #40c9c6;
    }
    .icon-message {
      color: #36a3f7;
    }
    .icon-money {
      color: #f4516c;
    }
    .icon-shopping {
      color: #34bfa3;
    }
    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }
    .card-panel-icon {
      float: left;
      font-size: 48px;
    }
    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;
      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }
      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}
</style>
