const order = {
  state: {
    subjectList: [
      {
        value: "1",
        label: "全部",
      },
      {
        value: "2",
        label: "红杏林学院",
      },
      {
        value: "3",
        label: "红杏林",
      },
      {
        value: "4",
        label: "红杏林文化",
      },
      {
        value: "5",
        label: "红杏林之家",
      },
    ],
    fileTypes: [
      { value: "1", label: "图片" },
      { value: "2", label: "视频" },
      { value: "3", label: "音频" },
    ],
    pickerOptions: {
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    uploadImgUrl:
      "http://121.199.5.67:8208/common-server/aliFile/upLoadFileNoSecret",
    uploadVideo: "http://121.199.5.67:8210/tencentNew/uploadVoid", // 上传视频素材,

    uploadStudentListenCourse:
      process.env.VUE_APP_BASE_API + "/inner-crm/pc/listen/importExcel", //导入听课表数据
  },
};

export default order;
