var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "el-menu",
        {
          staticClass: "header-menu",
          attrs: {
            "default-active": "P008",
            mode: "horizontal",
            "active-text-color": "#409EFF",
          },
          on: { select: _vm.activeMenu },
        },
        _vm._l(_vm.menu, function (item, index) {
          return _c("el-menu-item", { attrs: { index: item.projectCode } }, [
            _vm._v(_vm._s(item.projectName)),
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.user.avatar
                      ? _vm.$store.state.public.publicImgUrl + _vm.user.avatar
                      : _vm.Avatar,
                  },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/center" } },
                    [_c("el-dropdown-item", [_vm._v(" 个人中心 ")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.open },
                    },
                    [
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _vm._v(" 退出登录 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }