let lock = true;
let el = null;
let scrollTop = 0;
const MousedownEvent = new Event('mousedown', { bubbles: true });
const MouseupEvent = new Event('mouseup', { bubbles: true });
const fakeClickOutSide = () => {
    document.dispatchEvent(MousedownEvent);
    document.dispatchEvent(MouseupEvent);
    lock = true; // console.log('dispatchEvent');
};
const mousedownHandle = e => {
    if (document.querySelector('.dialog-box') && document.querySelector('.dialog-box').style.display != 'none') {
        document.querySelector('.dialog-box').addEventListener('scroll', handleScroll)
        return
    } else {
        window.addEventListener('scroll',handleScroll)
    }
};
const mousewheelHandle = e => {
    if (lock || e.target.classList.contains('el-select-dropdown__item') || e.target.parentNode.classList.contains('el-select-dropdown__item')) return;
    fakeClickOutSide();
};
const eventListener = (type) => {
    el[type + 'EventListener']('mousedown', mousedownHandle);
    window[type + 'EventListener']('mousewheel', mousewheelHandle);
    window[type + 'EventListener']('DOMMouseScroll', mousewheelHandle); // fireFox 3.5+ 
}
const handleScroll = () => {
    if (document.querySelector('.dialog-box') && document.querySelector('.dialog-box').style.display != 'none') {
        scrollTop = document.querySelector('.dialog-box').scrollTop
    } else {
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
    }
    if (!scrollTop) return;
    fakeClickOutSide();
}
export default {
    mounted() {
        el = this.$root.$el;
        el.addFakeClickOutSideEventCount = el.addFakeClickOutSideEventCount || 0;
        (!el.addFakeClickOutSideEventCount) && this.$nextTick(() => {
            eventListener('add');
        });
        el.addFakeClickOutSideEventCount += 1;
    },
    destroyed() {
        eventListener('remove');
        el.addFakeClickOutSideEventCount -= 1;
    },
}